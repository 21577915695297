import { StyleResponseDTO } from '../../Models/Styles';

export enum CharacterActions {
  UPDATE_ALL_CHARACTERS = 'UPDATE_ALL_CHARACTERS',
  ADD_TO_ALL_CHARACTERS = 'ADD_TO_ALL_CHARACTERS',
  UPDATE_SHOW_CHARACTERS = 'UPDATE_SHOW_CHARACTERS',
  UPDATE_TRAINING_CHARACTERS = 'UPDATE_TRAINING_CHARACTERS',
  SET_CURRENT_CHARACTER = 'SET_CURRENT_CHARACTER',
  ADD_TO_TRAINING_CHARACTERS = 'ADD_TO_TRAINING_CHARACTERS',
  SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE = 'SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE',
  SET_ALL_AVAILABLE_STYLES = 'SET_ALL_AVAILABLE_STYLES',
  SET_EPISODE_CHARACTERS = 'SET_EPISODE_CHARACTERS',
}

export const setAllAvailableStyles = (styles: StyleResponseDTO[]) => {
  return {
    type: CharacterActions.SET_ALL_AVAILABLE_STYLES,
    payload: styles,
  };
};

export interface UpdateCharacterAction {
  type: CharacterActions;
  payload: any;
}

export type CharacterActionTypes = UpdateCharacterAction;
