import HttpClient from '../../Utils/HttpClient';
import {
  adminApiPrefix,
  apiPrefix,
  characterApiPrefix,
  characterQCPrefix,
  feedbackPrefix,
  getCharactersByFiltersPrefix,
  studioApiPrefix,
  trainingEvalPrefix,
  v2Prefix,
} from '../../Constants/Constants';
import {
  AddCharactersToShowAndEpisodeRequest,
  AddShowCharacterRequest,
  AvailableCharactersWithFiltersRequest,
  Character,
  CharacterDatasetResponse,
  CharacterStatus,
  CharacterTrainingRequest,
  CharacterUpdateRequest,
  CreateCharacterWithMetadataRequest,
  CreateCharacterWithMetadataRequestV2,
  DeleteCharacterFromShowAndAllEpisodesRequest,
  DeleteShowCharacterRequest,
  GetCharactersByFilterRequest,
  ShowCharacterDetailsUpdateRequest,
  ShowCharacterMetadataUpdateRequest,
  UpdateCharacterQCRequest,
} from '../../Models/Character';
import { FeedbackRequest } from '../../Models/Feedback';
import { SaveImageResponse } from '../../Models/SaveImage';

export const createCharacterWithMetadata = async (
  createCharacterRequest: CreateCharacterWithMetadataRequest,
  characterImageLink: string = ''
) => {
  return HttpClient.post(
    `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/create-with-meta?characterImageLink=${characterImageLink}`,
    createCharacterRequest,
    {
      resourceId: createCharacterRequest.showId,
    },
    true,
    true
  );
};

export const createCharacterWithMetadataV2 = async (
  createCharacterRequest: CreateCharacterWithMetadataRequestV2
): Promise<Character> => {
  return HttpClient.post(
    `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/v2/create-with-meta`,
    createCharacterRequest,
    {},
    true,
    true
  );
};

export const characterIncreaseQuotaRequest = async (request: FeedbackRequest, showId: string) => {
  return HttpClient.post(
    `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/quota-increase`,
    request,
    {
      resourceId: showId,
    },
    true,
    true
  );
};

export const deleteTrainedCharacter = async (characterId: string, showId: string) => {
  try {
    return HttpClient.delete(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/delete-character`,
      { characterId: characterId, showId: showId },
      { resourceId: showId }
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchTrainingOptions = async () => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${characterApiPrefix}/training/options`);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchTrainingEvalStatus = async (characterId: String) => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}${trainingEvalPrefix}/${characterId}`);
  } catch (error) {
    console.error(error);
  }
};

export const fetchQCByCharacterId = async (characterId: String) => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}${characterQCPrefix}/${characterId}`);
  } catch (error) {
    console.error(`Character QC Details cannot be obtained for ${characterId}`);
    return null;
  }
};

export const updateQCByCharacterId = async (updateQCRequest: UpdateCharacterQCRequest) => {
  try {
    return HttpClient.put(`${apiPrefix}${v2Prefix}${characterQCPrefix}`, updateQCRequest);
  } catch (error) {
    console.error(`Character QC Update cannot be done for ${updateQCRequest.characterId}`);
    return null;
  }
};

export const fetchCharactersForShow = async (showId: String) => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${characterApiPrefix}/show/${showId}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Character fetch for show ${showId} failed with error: ${error}`);
    return null;
  }
};

export const fetchCharactersForEpisode = async (episodeId: String) => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/episode/${episodeId}`,
      {
        resourceId: episodeId,
      },
      true
    );
  } catch (error) {
    console.error(`Character fetch for episode ${episodeId} failed with error: ${error}`);
    return null;
  }
};

export const updateCharacterMetadata = async (
  showId: string,
  updateRequest: ShowCharacterMetadataUpdateRequest
) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/show/update`,
      updateRequest,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Character metadata updated failed with error : ${error}`);
    return null;
  }
};

export const fetchCharactersForState = async (state: CharacterStatus) => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${characterApiPrefix}/state/${state}`);
  } catch (error) {
    console.error(`Character fetch for state ${state} failed with error: ${error}`);
    return null;
  }
};

export const fetchAllCharacterAvailable = async (showId: string): Promise<Character[]> => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/all/show/${showId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Character fetch for show ${showId} failed with error: ${error}`);
    throw new Error(`Character fetch for show ${showId} failed`);
  }
};

export const fetchAllCharacterAvailableFilteredPaginated = async (
  showId: string,
  page: number,
  request: AvailableCharactersWithFiltersRequest
): Promise<Character[]> => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/filter/show/${showId}?page=${page}`,
      request,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Character fetch for show ${showId} failed with error: ${error}`);
    throw new Error(`Character fetch for show ${showId} failed`);
  }
};

export const fetchTrainingCharactersForShow = async (showId: string) => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/train/characters/${showId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Training character fetch for ${showId} failed with error: ${error}`);
    return null;
  }
};

export const fetchTrainingCharactersByUserForShow = async (showId: string) => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/train/characters/creator?showId=${showId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Training character fetch for user failed with error: ${error}`);
    return null;
  }
};

export const fetchTrainingCharactersByUser = async () => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/train/characters/creator`,
      {},
      false
    );
  } catch (error) {
    console.error(`Training character fetch for user failed with error: ${error}`);
    throw error;
  }
};

export const trainCharacterWithUploadedImages = async (
  trainingRequest: CharacterTrainingRequest
) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/images`,
      trainingRequest,
      {
        resourceId: trainingRequest.showId,
      }
    );
  } catch (error) {
    console.error(`Unable to train character ${trainingRequest.name} due to ${error}`);
    return null;
  }
};

export const addShowCharacters = async (showCharacterUpdateRequest: AddShowCharacterRequest[]) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/batch`,
      showCharacterUpdateRequest,
      {
        resourceId: showCharacterUpdateRequest[0].showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const updateShowCharacters = async (
  showCharacterUpdateRequest: AddShowCharacterRequest[]
) => {
  try {
    return HttpClient.put(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/batch`,
      showCharacterUpdateRequest,
      {
        resourceId: showCharacterUpdateRequest[0].showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const updateShowCharactersState = async (
  showCharacterUpdateRequest: AddShowCharacterRequest
) => {
  try {
    return HttpClient.put(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/state`,
      showCharacterUpdateRequest,
      {
        resourceId: showCharacterUpdateRequest.showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const deleteShowCharacters = async (
  showCharacterUpdateRequest: DeleteShowCharacterRequest[]
) => {
  try {
    return HttpClient.delete(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/batch`,
      showCharacterUpdateRequest,
      {
        resourceId: showCharacterUpdateRequest[0].showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const deleteCharactersFromShowAndAllEpisodes = async (
  request: DeleteCharacterFromShowAndAllEpisodesRequest
) => {
  try {
    return HttpClient.delete(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/show-and-episode/batch`,
      request,
      {
        resourceId: request.showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const addCharactersToShowAndEpisode = async (
  request: AddCharactersToShowAndEpisodeRequest
) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/show-and-episode/batch`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const updateShowCharacterDetails = async (request: ShowCharacterDetailsUpdateRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}/show/update-details`,
      request,
      {
        resourceId: request.showId,
      }
    );
  } catch (error) {
    console.error(`Character addition failed : ${error}`);
    return null;
  }
};

export const getCharactersByFilters = async (
  getCharactersByFiltersRequest: GetCharactersByFilterRequest
) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${characterApiPrefix}${getCharactersByFiltersPrefix}`,
      getCharactersByFiltersRequest
    );
  } catch (error) {
    console.error(`Unable to get characters ${error}`);
    return null;
  }
};

export const createTrainingCharacter = async (trainingRequest: CharacterTrainingRequest) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/create-character`,
      trainingRequest,
      {
        resourceId: trainingRequest.showId,
      }
    );
  } catch (error) {
    console.error(`Unable to create character ${trainingRequest.name} due to ${error}`);
    return null;
  }
};

export const fetchValidationStatus = async (characterId: string) => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}/qc/training-images/${characterId}`);
  } catch (error) {
    console.error(`Unable to fetch validation for ${characterId}`);
  }
};

export const fetchComfyUILinks = async (characterId: String) => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/dataset/${characterId}`
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateChosenLinksComfy = async (characterId: String, imageUrls: String[]) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/dataset/select`,
      {
        character_id: characterId,
        chosen_urls: imageUrls,
      }
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const trainCharacterUsingImgUrls = async (characterId: String) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/character/img-urls`,
      {
        characterId: characterId,
      }
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Unable to train character ${characterId}`);
  }
};

export const getCharacterMetadataDescription = async (image: string) => {
  try {
    return await HttpClient.post(`${apiPrefix}${v2Prefix}/chat/generate-image-metadata`, {
      imageUrl: image,
    });
  } catch (e) {
    console.log('Error while fetching popular shows');
    throw e;
  }
};

export const sendCharacterForDatasetGenerations = async (
  characterId: string
): Promise<Character> => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/dataset`,
      {
        character_id: characterId,
      }
    );
  } catch (error) {
    console.error(`Could not send character with ${characterId} for dataset generations`);
    throw new Error(`Could not send character with ${characterId} for training!`);
  }
};

export const trainCharacterUsingId = async (showId: string, characterId: string) => {
  try {
    return HttpClient.postWithoutBody(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/character/${characterId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to train character ${characterId} due to ${error}`);
    return null;
  }
};

export const publishCharacterToShow = async (showId: string, characterId: string) => {
  try {
    return HttpClient.postWithoutBody(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/publish-character/${characterId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to publish character ${characterId} due to ${error}`);
    return null;
  }
};

export const updateCharacterDetails = async (
  showId: string,
  updateRequest: CharacterUpdateRequest
) => {
  try {
    return HttpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/update-character`,
      updateRequest,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to update character ${updateRequest.characterId} due to ${error}`);
    return null;
  }
};

export const updateCharacterFeedback = async (characterId: string) => {
  try {
    return HttpClient.put(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}${feedbackPrefix}/${characterId}`,
      {}
    );
  } catch (error) {
    console.error(`Unable to update character feedback for ${characterId} due to ${error}`);
    return null;
  }
};

export const getCharacterById = async (characterId: string): Promise<Character> => {
  try {
    return HttpClient.get(`${apiPrefix}${studioApiPrefix}${characterApiPrefix}/${characterId}`);
  } catch (error) {
    console.error(`Unable to get character ${characterId} due to ${error}`);
    throw error;
  }
};

export const getDatasetImagesForCharacter = async (
  characterId: string
): Promise<CharacterDatasetResponse> => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/dataset/${characterId}`
    );
  } catch (error) {
    console.error(`Unable to get dataset images for ${characterId} due to ${error}`);
    throw error;
  }
};

export const getCollectionImagesForCharacter = async (
  characterId: string
): Promise<SaveImageResponse[]> => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${characterApiPrefix}/train/collection/${characterId}`
    );
  } catch (error) {
    console.error(`Unable to get collection images for ${characterId} due to ${error}`);
    throw error;
  }
};
