export enum TrackingEvents {
  publishShow = 'publishShow',
  publishEpisode = 'publishEpisode',
  publishSuccess = 'publishSuccess',
  publishFailure = 'publishFailure',
  previewLoadStart = 'previewLoadStart',
  previewLoadEnd = 'previewLoadEnd',
  updateShowSuccess = 'updateShowSuccess',
  updateShowFailure = 'updateShowFailure',
  userLogin = 'userLogin',
  userSignup = 'userSignup',
  userLogout = 'userLogout',
  userIsUnverified = 'userIsUnverified',
  verificationEmailSent = 'verificationEmailSent',
  detailsNextButtonClicked = 'detailsNextButtonClicked',
  seriesTitleEntered = 'seriesTitleEntered',
  seriesDescriptionEntered = 'seriesDescriptionEntered',
  seriesGenreSelected = 'seriesGenreSelected',
  seriesTropeSelected = 'seriesTropeSelected',
  styleThumbnailClicked = 'styleThumbnailClicked',
  styleConfirmed = 'styleConfirmed',
  styleNextButtonClicked = 'styleNextButtonClicked',
  styleToggled = 'styleToggled',
  thumbnailUploaded = 'thumbnailUploaded',
  createEpisode1ButtonClicked = 'createEpisode1ButtonClicked',
  uploadEpisode1ButtonClicked = 'uploadEpisode1ButtonClicked',
  uploadEpisodeButtonClicked = 'uploadEpisodeButtonClicked',
  uploadImageCanvas = 'uploadImageCanvas',
  generateEpisodeButtonClicked = 'generateEpisodeButtonClicked',
  comicEditButtonClicked = 'comicEditButtonClicked',
  comicListItemOpened = 'comicListItemOpened',
  episodeListItemOpened = 'episodeListItemOpened',
  episodeEditButtonClicked = 'episodeEditButtonClicked',
  t2iGenerateButtonClicked = 't2iGenerateButtonClicked',
  t2iThumbnailGenerateButtonClicked = 't2iThumbnailGenerateButtonClicked',
  i2iGenerateButtonClicked = 'i2iGenerateButtonClicked',
  pwwGenerateButtonClicked = 'pwwGenerateButtonClicked',
  inpaintGenerateButtonClicked = 'inpaintGenerateButtonClicked',
  instructPix2PixGenerateButtonClicked = 'instructPix2PixGenerateButtonClicked',
  upscalerGenerateButtonClicked = 'upscalerGenerateButtonClicked',
  discordButtonClicked = 'discordButtonClicked',
  buyCoinsButtonClicked = 'buyCoinsButtonClicked',
  favoriteImageClicked = 'favoriteImageClicked',
  imageRatingClicked = 'imageRatingClicked',
  objectErasedOnImage = 'objectErasedOnImage',
  generateImage = 'generateImage',
  generateThumbnailImage = 'generateThumbnailImage',
  saveImage = 'saveImage',
  downloadImage = 'downloadImage',
  sendTo = 'sendTo',
  showInitModalClosed = 'showInitModalClosed',
  showDeleted = 'showDeleted',
  episodeDeleted = 'episodeDeleted',
  studioOpen = 'studioOpen',
  studioClose = 'studioClose',
  createNewEpisode = 'createNewEpisode',
  // characters
  characterEdited = 'characterEdited',
  characterTrained = 'characterTrained',
  characterPublished = 'characterPublished',
  characterAdded = 'characterAdded',
  charactedAddedToShow = 'characterAddedToShow',
  characterNameModified = 'characterNameModified',
  characterRemovedFromShow = 'characterRemovedFromShow',
  createCharacterButton = 'createCharacterButton',
  viewCharacterInTrainingButton = 'viewCharacterInTrainingButton',
  viewCharacterLibraryTrainingButton = 'viewCharacterLibraryTrainingButton',
  characterCreationPageNextButton = 'characterCreationPageNextButton',
  characterCreationPageBackButton = 'characterCreationPageBackButton',
  characterCreationTrainCharacterButton = 'characterCreationTrainCharacterButton',
  characterCreationAPISuccess = 'characterCreationAPISuccess',
  characterCreationUploadImage = 'characterCreationUploadImage',
  characterMetadataWithAiSuccess = 'characterMetadataWithAiSuccess',
  characterMetadataWithAiFailure = 'characterMetadataWithAiFailure',
  createdCharacterUsingMetadataAi = 'createdCharacterUsingMetadataAi',
  characterRateLimitFormView = 'characterRateLimitFormView',
  characterRateLimitFormSubmit = 'characterRateLimitFormSubmit',
  // character end
  showDetailsOpenModal = 'showDetailsOpenModal',
  showDetailsCloseModal = 'showDetailsCloseModal',
  showDetailsModalTabChange = 'showDetailsModalTabChange',
  characterViewModalChanges = 'characterViewModalChanges',
  shareShow = 'shareShow',
  copyLink = 'copyLink',
  viewShow = 'viewShow',
  shareEpisode = 'shareEpisode',
  viewEpisode = 'viewEpisode',
  socialShare = 'socialShare',
  shareIconClicked = 'shareIconClicked',
  dashtoonSettingsModalOpened = 'dashtoonSettingsModalOpened',
  dashtoonSettingsModalClosed = 'dashtoonSettingsModalClosed',
  styleSelectionModalOpened = 'styleSelectionModalOpened',
  styleSelectionModalClosed = 'styleSelectionModalClosed',
  manageCharactersModalOpened = 'manageCharactersModalOpened',
  manageCharactersModalClosed = 'manageCharactersModalClosed',
  manageTeamModalOpened = 'manageTeamModalOpened',
  manageTeamModalClosed = 'manageTeamModalClosed',

  // payments/subscription
  userBillingPopupTriggered = 'userBillingPopupTriggered',
  userQuotaUsageButtonClicked = 'userQuotaUsageButtonClicked',
  userSettingsBillingButtonClicked = 'userSettingsBillingButtonClicked',
  paymentSubscriptionModalOpened = 'paymentSubscriptionModalOpened',
  paymentSubscriptionModalClosed = 'paymentSubscriptionModalClosed',
  paymentSubscriptionPageOpened = 'paymentSubscriptionPageOpened',
  paymentSubscriptionCheckoutInitiated = 'paymentSubscriptionCheckoutInitiated',
  paymentSubscriptionCheckoutInitiatedFailure = 'paymentSubscriptionCheckoutInitiatedFailure',
  paymentTopupInitiated = 'paymentTopupInitiated',
  paymentTopupRedirectSuccess = 'paymentTopupRedirectSuccess',
  paymentTopupRedirectFailure = 'paymentTopupRedirectFailure',
  paymentSubscriptionRedirectSuccess = 'paymentSubscriptionRedirectSuccess',
  paymentSubscriptionRedirectFailure = 'paymentSubscriptionRedirectFailure',
  quotaLeftButtonClicked = 'quotaLeftButtonClicked',
  changesToDashtoonCreditsModalClosed = 'changesToDashtoonCreditsModalClosed',
  changesToDashtoonCreditsModalOpened = 'changesToDashtoonCreditsModalOpened',
  newPlansModalClosed = 'newPlansModalClosed',
  newPlansModalOpened = 'newPlansModalOpened',
  userQuotaUsageModalOpened = 'userQuotaUsageModalOpened',
  userQuotaUsageModalClosed = 'userQuotaUsageModalClosed',
  whatsNewModalClosed = 'whatsNewModalClosed',
  whatsNewModalOpened = 'whatsNewModalOpened',
  onLoadCreditsBalance = 'onLoadCreditsBalance',

  disabledButtonClicked = 'disabledButtonClicked',
  segmentRequest = 'segmentRequest',
  nsfwCount = 'nsfwCount',
  uploadImageToLibrary = 'uploadImageToLibrary',
  promptLibrarySearch = 'promptLibrarySearch',
  imageLibrarySearch = 'imageLibrarySearch',
  selectImageFromLibrary = 'selectImageFromLibrary',
  imageLibrarySearchButtonClicked = 'imageLibrarySearchButtonClicked',
  editMultiInpaintSegments = 'editMultiInpaintSegments',
  imageGenerationFailed = 'imageGenerationFailed',
  imageGenerationLost = 'imageGenerationLost',
  imageGenerationRequestFailed = 'imageGenerationRequestFailed',
  pinnedImageClicked = 'pinnedImageClicked',
  unpinnedImageClicked = 'unpinnedImageClicked',
  rightClickMenu = 'rightClickMenu',
  autoInpaintButtonClick = 'autoInpaintButtonClick',
  scheduleEpisode = 'scheduleEpisode',
  sizeLimitExceeded = 'sizeLimitExceeded',
  imageLoaded = 'imageLoaded',
  imageLoadTimeOut = 'imageLoadTimeOut',
  globalImageLoad = 'globalImageLoad',
  imageLoadFailed = 'imageLoadFailed',
  globalImageLoadFailed = 'globalImageLoadFailed',
  imageGenerationTime = 'imageGenerationTime',
  imageGenerationDone = 'imageGenerationDone',
  refreshScreen = 'refreshScreen',
  selectedBugType = 'selectedBugType',
  bugReport = 'bugReport',
  bugReportIconClick = 'bugReportIconClick',
  uiBugReport = 'uiBugReport',
  uiBug = 'uiBug',
  openLibrary = 'openLibrary',
  clickCreateLibrary = 'clickCreateLibrary',
  clickSendToLibrary = 'clickSendToLibrary',
  clickSendToCreateLibrary = 'clickSendToCreateLibrary',
  dragFromLibrary = 'dragFromLibrary',
  clickOnOpenCollection = 'clickOnOpenCollection',
  clickOnOpenAllCollections = 'clickOnOpenAllCollections',
  clickOnOpenHistory = 'clickOnOpenHistory',
  clickOnOpenFavorites = 'clickOnOpenFavorites',
  clickOnOpenPinned = 'clickOnOpenPinned',
  clickOnOpenDownloadedImages = 'clickOnOpenDownloadedImages',
  searchLibraryCollections = 'searchLibraryCollections',
  createCollection = 'createCollection',
  // deprecated
  liveMode = 'liveMode',
  buttonClicked = 'buttonClicked',
  toolbarButtonClicked = 'toolbarButtonClicked',
  frameMenuTabClicked = 'frameMenuTabClicked',
  rightMenuToolClicked = 'rightMenuToolClicked',
  colorChanged = 'colorChanged',
  strokeSizeChanged = 'strokeSizeChanged',
  upscaleButtonClicked = 'upscaleButtonClicked',
  removeBackgroundButtonClicked = 'removeBackgroundButtonClicked',
  magicEraserButtonClicked = 'magicEraserButtonClicked',
  maskModeChanged = 'maskModeChanged',
  autoInpaintButtonClicked = 'autoInpaintButtonClicked',
  imageAppliedToCanvas = 'imageAppliedToCanvas',
  imageAppliedToCanvasFromLiveMode = 'imageAppliedToCanvasFromLiveMode',
  imageGenerationLiveModeSentToQueue = 'imageGenerationLiveModeSentToQueue',
  imageGeneratedFromLiveMode = 'imageGeneratedFromLiveMode',
  liveModeError = 'liveModeError',
  fontSizeChanged = 'fontSizeChanged',
  fontChanged = 'fontChanged',
  dragBubble = 'dragBubble',
  dragImageFromHistory = 'dragImageFromHistory',
  dragImageFromPreview = 'dragImageFromPreview',
  dropImageFromHistory = 'dropImageFromHistory',
  dropBubble = 'dropBubble',
  popularShowClicked = 'popularShowClicked',
  youtubeTutorialClicked = 'youtubeTutorialClicked',
  dashExplorerShowClicked = 'dashExplorerShowClicked',
  socialIconClicked = 'socialIconClicked',
  homeScreenOpened = 'homeScreenOpened',
  showCreated = 'showCreated',
  infoButtonClicked = 'infoButtonClicked',
  styleLeftPanelClicked = 'styleLeftPanelClicked',
  apiError = 'apiError',
  maskToggleChanged = 'maskToggleChanged',
  shortcutModalOpened = 'shortcutModalOpened',
  gradientToolSelected = 'gradientToolSelected',
  autoGradientButtonClicked = 'autoGradientButtonClicked',
  homeScreenTabClicked = 'homeScreenTabClicked',
  commentsIconClicked = 'commentsIconClicked',
  likesIconClicked = 'likesIconClicked',
  viewsIconClicked = 'viewsIconClicked',
  bannerStatsClicked = 'bannerStatsClicked',
  widgetTitleShowAll = 'widgetTitleShowAll',
  creatorWidgetClicked = 'creatorWidgetClicked',
  joinCommunityWidgetClicked = 'joinCommunityWidgetClicked',
  creatorProgramLinkClicked = 'creatorProgramLinkClicked',
  myDashtoonClicked = 'myDashtoonClicked',

  // upscale image
  upscaleImage = 'upscaleImage',

  // ftue
  ftueGetStarted = 'ftueGetStarted',
  ftueNext = 'ftueNext',
  ftueBack = 'ftueBack',
  ftueDone = 'ftueDone',
  ftueSkip = 'ftueSkip',

  //onboarding
  onboardingStarted = 'onboardingStarted',
  onboardingNext = 'onboardingNext',
  onboardingDone = 'onboardingDone',

  // text enhancer
  enhancePrompt = 'enhancePrompt',

  //perf
  webVitals = 'webVitals',

  // expression for generations
  useExpressionDropdown = 'useExpressionDropdown',

  //thumbnails
  setAsThumbnail = 'setAsThumbnail',

  //story
  createStory = 'createStory',
  skipStoryMode = 'skipStoryMode',
  createPanels = 'createPanels',
  getStoryDetails = 'getStoryDetails',
  setStoryCharacter = 'setStoryCharacter',
  editModeCreateStory = 'editModeCreateStory',
  editModeCreatePanels = 'editModeCreatePanels',
  retryCreatePanels = 'retryCreatePanels',
  storyCompleted = 'storyCompleted',
  alternateStory = 'alternateStory',
  promptPresetClicked = 'promptPresetClicked',
  storyFailed = 'storyFailed',

  //story crafter
  storyCrafterCreatePanels = 'storyCrafterCreatePanels',
  storyCrafterPanelAdded = 'storyCrafterPanelAdded',
  storyCrafterChangesSaved = 'storyCrafterChangesSaved',
  storyCrafterCreateDashtoon = 'storyCrafterCreateDashtoon',
  storyCrafterCompleted = 'storyCrafterCompleted',
  storyCrafterFailed = 'storyCrafterFailed',

  //tooltips
  tooltipNext = 'tooltipNext',
  tooltipBack = 'tooltipBack',
  tooltipDone = 'tooltipDone',
  tooltipSkip = 'tooltipSkip',

  //improvement tips
  openAllTips = 'openAllTips',
  closeAllTips = 'closeAllTips',
  tipClicked = 'tipClicked',
  videoPlayed = 'videoPlayed',
  videoPaused = 'videoPaused',
  videoClosed = 'videoClosed',
  videoCompleted = 'videoCompleted',

  //segment
  segmentButtonClicked = 'segmentButtonClicked',
  segmentPeopleButtonClicked = 'segmentPeopleButtonClicked',
  segmentPeopleRequestFailed = 'segmentPeopleRequestFailed',
  bulkSegmentPeopleButtonClicked = 'bulkSegmentPeopleButtonClicked',
  segmentToolClicked = 'segmentToolClicked',

  //feedback
  feedbackSubmitted = 'feedbackSubmitted',
  feedbackShown = 'feedbackShown',

  //twitter share
  shareButtonClicked = 'shareButtonClicked',
  shareOnTwitterClicked = 'shareOnTwitterClicked',
  twitterAuthPopupOpened = 'twitterAuthPopupOpened',
  twitterAuthSuccess = 'twitterAuthSuccess',
  twitterPreviewModalOpened = 'twitterPreviewModalOpened',
  twitterPreviewModalClosed = 'twitterPreviewModalClosed',
  twitterPostCreated = 'twitterPostCreated',

  // blend mode
  blendModeChanged = 'blendModeChanged',

  //brush
  brushTypeChanged = 'brushTypeChanged',
  patternUploaded = 'patternUploaded',
  patternRotationChanged = 'patternRotationChanged',
  brushHardnessChanged = 'brushHardnessChanged',
  pressureSensitivityChanged = 'pressureSensitivityChanged',
  brushDistanceChanged = 'brushDistanceChanged',

  //eraser
  eraaserWidthChanged = 'eraaserWidthChanged',

  // object hierarchy
  objectHierarchyOpened = 'objectHierarchyOpened',
  objectLayerClicked = 'objectLayerClicked',

  // tutorial canvas mode
  getStartedTutorialCanvasAdded = 'getStartedTutorialCanvasAdded',

  // version
  refreshUserPage = 'refreshUserPage',

  // Auto inpaint
  autoInpaintSelect = 'autoInpaintSelect',
  autoInpaintGeneration = 'autoInpaintGeneration',

  // Enhanced Generation
  enhancedGenerationSelect = 'enhancedGenerationSelect',
  fasterGenerationCheck = 'fasterGenerationCheck',

  // storycrafter
  storyInitialOptionSelected = 'storyInitialOptionSelected',
  createPromptToStory = 'createPromptToStory',
  createScreenplay = 'createScreenplay',
  retryFailedScreenplay = 'retryFailedScreenplay',
  storyCharactersExtractionCompleted = 'storyCharactersExtractionCompleted',
  screenplayViewToggle = 'screenplayViewToggle',
  duplicateBlock = 'duplicateBlock',
  deleteBlock = 'deleteBlock',
  updateBlock = 'updateBlock',
  regenerateBlockClicked = 'regenerateBlockClicked',
  regenerateEditedPanels = 'regenerateEditedPanels',
  characterAddedToBlock = 'characterAddedToBlock',
  characterDeletedFromBlock = 'characterDeletedFromBlock',
  dialogAddedToBlock = 'dialogAddedToBlock',
  dialogDeletedFromBlock = 'dialogDeletedFromBlock',
  panelImageAddedToBlock = 'panelImageAddedToBlock',
  panelImageDeletedFromBlock = 'panelImageDeletedFromBlock',
  newBlocksAdded = 'newBlocksAdded',
  storyPreviewOpened = 'storyPreviewOpened',
  storyPreviewClosed = 'storyPreviewClosed',
  canvasPreviewOpened = 'canvasPreviewOpened',
  canvasPreviewClosed = 'canvasPreviewClosed',
  storyImageGenerationFailed = 'storyImageGenerationFailed',
  screenplayGenerationFailed = 'screenplayGenerationFailed',
  storyGradientFailed = 'storyGradientFailed',
  storyLastBlockSeen = 'storyLastBlockSeen',
  storyLastImageGenerated = 'storyLastImageGenerated',
  generateBlockImagesBulk = 'generateBlockImagesBulk',

  // new characters modal
  editCharacterClicked = 'editCharacterClicked',
  newCharacterClicked = 'newCharacterClicked',
  characterDeleted = 'characterDeleted',
  characterUpdated = 'characterUpdated',
  duplicateCharactersDeleted = 'duplicateCharactersDeleted',

  // canvas v3
  canvasMounted = 'canvasMounted',
  canvasUnmounted = 'canvasUnmounted',
  canvasModeChanged = 'canvasModeChanged',
  leftMenuToolClicked = 'leftMenuToolClicked',
  rightBarButtonClicked = 'rightBarButtonClicked',
  iconButtonClicked = 'iconButtonClicked',
  toolMenuButtonClicked = 'toolMenuButtonClicked',
  framePresetClicked = 'framePresetClicked',
  magicEraserStrokeSizeChanged = 'magicEraserStrokeSizeChanged',
  maskStrokeSizeChanged = 'maskStrokeSizeChanged',
  autoMaskButtonClicked = 'autoMaskButtonClicked',
  autoMaskFailed = 'autoMaskFailed',
  bubbleTypeChanged = 'bubbleTypeChanged',
  tailTypeChanged = 'tailTypeChanged',
  bubbleFillColourChanged = 'bubbleFillColourChanged',
  bubbleStrokeColourChanged = 'bubbleStrokeColourChanged',
  bubbleWidthChanged = 'bubbleWidthChanged',
  tailToggleChanged = 'tailToggleChanged',
  textModeChanged = 'textModeChanged',
  dropDownMenuButtonClicked = 'dropDownMenuButtonClicked',
  saveCanvasTriggered = 'saveCanvasTriggered',
  publishButtonClicked = 'publishButtonClicked',
  showNameEditing = 'showNameEditing',
  styleButtonClicked = 'styleButtonClicked',
  characterButtonClicked = 'characterButtonClicked',
  manageTeamButtonClicked = 'manageTeamButtonClicked',
  modalOpened = 'modalOpened',
  modalClosed = 'modalClosed',

  //comments
  commentAdded = 'commentAdded',
  commentResolved = 'commentResolved',
  commentMenuOpened = 'commentMenuOpened',
  commentMenuClosed = 'commentMenuClosed',
  commentMenuTabChanged = 'commentMenuTabChanged',
  commentClickedFromMenu = 'commentClickedFromMenu',
  commentAddFailed = 'commentAddFailed',
  commentResolveFailed = 'commentResolveFailed',
  commentDeleted = 'commentDeleted',
  commentDeleteFailed = 'commentDeleteFailed',
  commentFilterChanged = 'commentFilterChanged',
  commentReplied = 'commentReplied',
  commentReplyFailed = 'commentReplyFailed',
  commentEdited = 'commentEdited',
  commentEditFailed = 'commentEditFailed',

  //image
  imageFavoriteClicked = 'imageFavoriteClicked',
  imageCancelClicked = 'imageCancelClicked',
  nextImageClicked = 'nextImageClicked',
  previousImageClicked = 'previousImageClicked',

  //shapes
  shapePropertiesChanged = 'shapePropertiesChanged',

  //rightMenu
  genModeChanged = 'genModeChanged',
  strengthChanged = 'strengthChanged',
  batchSizeChanged = 'batchSizeChanged',
  giveMeADifferentOneClicked = 'giveMeADifferentOneClicked',

  //history
  historyTabChanged = 'historyTabChanged',
  imageSelectedFromHistory = 'imageSelectedFromHistory',

  //canvas
  canvasInteraction = 'canvasInteraction',

  episodeProjectStatusDropdownClicked = 'episodeProjectStatusDropdownClicked',
  episodeProjectStatusChanged = 'episodeProjectStatusChanged',
  assignUserToEpisode = 'assignUserToEpisode',
  removeUserFromEpisode = 'removeUserFromEpisode',
  addUserToShow = 'addUserToShow',
  removeUserFromShow = 'removeUserFromShow',
  updateRoleOfUserInShow = 'changeRoleOfUserInShow',

  //gaze
  addCharacterHeadMask = 'addCharacterHeadMask',
  gazeMaskGenerationSuccess = 'gazeMaskGenerationSuccess',
  gazeMaskGenerationFailure = 'gazeMaskGenerationFailure',
  gazeExpressionApplied = 'gazeExpressionApplied',
  gazeExpressionRejected = 'gazeExpressionRejected',
  gazeMaskModeChanged = 'gazeMaskModeChanged',
  gazeMaskModified = 'gazeMaskModified',
  gazeMaskCreated = 'gazeMaskCreated',
  gazeMaskSelected = 'gazeMaskSelected',
  gazeParameterChanged = 'gazeParameterChanged',
  gazeToolClosed = 'gazeToolClosed',
}

export enum SendToTypes {
  t2i = 't2i',
  i2i = 'i2i',
  inpaint = 'inpaint',
  controlnet_t2i = 'controlnet_t2i',
  controlnet_i2i = 'controlnet_i2i',
  controlnet_inpaint = 'controlnet_inpaint',
  upscale = 'upscale',
}
