import React, { useState, useEffect } from 'react';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
  User,
} from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { configureTracking } from '../../../Utils/Analytics';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../Redux/ReduxInterface';
import { message } from 'antd';
import { FirebaseError } from 'firebase/app';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { configureDashUser } from '../../../Utils/DashAnalytics';

export enum LocaleType {
  en = 'English',
  ko = '한국어',
  ja = '日本語',
  zh_CN = '中文',
  zh_TW = '中文(繁體)',
}

const Settings: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const theme = useTheme();
  const [resetEmail, setResetEmail] = useState('');
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState<LocaleType>('English' as LocaleType);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setUser(user);
      setResetEmail(user?.email ?? '');
    });

    return () => unsubscribe();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setPasswordError('');
  };

  const handleSubmit = () => {
    // Validate form inputs
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordError(t('Please fill in all fields.'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordError(t('Passwords do not match.'));
      return;
    }

    // Clear any previous error
    setPasswordError('');

    // Call the function to change the password
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    // Your existing code to change the password
    if (user) {
      setLoading(true);

      try {
        // Reauthenticate the user before changing the password
        const credential = EmailAuthProvider.credential(user.email!, currentPassword);
        await reauthenticateWithCredential(user, credential);

        await updatePassword(user, newPassword);

        // Password change successful
        message.success(t('Password changed successfully'));
        setLoading(false);

        // Close the modal
        handleCloseModal();
      } catch (error) {
        setLoading(false);

        console.error('Error changing password');
        message.error(t('Failed to change password'));
      }
    }
  };

  const handleForgotPassword = async () => {
    if (!resetEmail) {
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      message.success('Password reset email sent');
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        console.error('Error sending password reset email:', error.message);
      }
    }
  };

  return (
    <>
      <Box sx={{ width: '400px', m: '5px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 'small', margin: 0, padding: 0 }}>{t('Name')} </div>
            {user?.displayName == null ? 'John Doe' : user.displayName} <br></br>
            <div style={{ fontSize: 'small', margin: 0, padding: 0 }}>{t('Email')} Id</div>
            {user?.email == null ? '' : user.email} <br></br>
          </div>
          <div>
            <IconButton sx={{ p: 0 }}>
              <Avatar
                style={{ width: '60px', height: '60px' }}
                alt={user?.displayName == null ? 'John Doe' : user.displayName}
                src={user?.photoURL == null ? 'avatar' : user.photoURL}
              />
            </IconButton>
          </div>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '8px',
            marginTop: '20px',
          }}
        >
          {' '}
          <Button variant={'contained'} onClick={handleOpenModal}>
            {t('Change Password')}{' '}
          </Button>{' '}
          <Button onClick={handleForgotPassword} variant={'contained'}>
            {t('Reset Password')}{' '}
          </Button>{' '}
        </div>
        <hr></hr>
        <div style={{ fontSize: 'small', marginTop: '20px' }}>{t('Language')} </div>
        <FormControl
          sx={{
            marginRight: '10px',
            width: '200px',
            paddingRight: 5,
          }}
        >
          <Select
            variant="outlined"
            value={i18n.language}
            renderValue={p => LocaleType[p as keyof typeof LocaleType]}
            onChange={e => {
              const lang = e.target.value;
              i18n.changeLanguage(lang);
            }}
            multiple={false}
            sx={{
              backgroundColor: theme.palette.background.paper,
              textOverflow: 'ellipsis',
              mb: '-4px',
              mt: '5px',
              height: '30px',
            }}
          >
            {Object.keys(LocaleType)
              .filter(value => typeof value === 'string')
              .map(type => (
                <MenuItem
                  key={type}
                  value={type}
                  sx={{
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light,
                      borderColor: theme.palette.text.primary,
                      color: theme.palette.text.primary,
                    },
                  }}
                >
                  <div>{LocaleType[type as keyof typeof LocaleType]}</div>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <div
          style={{
            background: theme.palette.background.default,
            padding: '16px',
            borderRadius: '8px',
            width: '400px',
          }}
        >
          <h3>{t('Change Password')}</h3>
          <div>
            <TextField
              label={t('Current Password')}
              type="password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              error={passwordError !== ''}
              helperText={passwordError}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label={t('New Password')}
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              error={passwordError !== ''}
              helperText={passwordError}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label={t('Confirm New Password')}
              type="password"
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
              error={passwordError !== ''}
              helperText={passwordError}
              fullWidth
              margin="normal"
              required
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                style={{ marginTop: '2%' }}
                onClick={handleSubmit}
              >
                {t('Change Password')}
              </LoadingButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Settings;
