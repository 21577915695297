export const BANNER_THUMBNAIL_V2_HEIGHT = 570;
export const BANNER_THUMBNAIL_V2_WIDTH = 824;

export const WIDGET_THUMBNAIL_V2_HEIGHT = 440;
export const WIDGET_THUMBNAIL_V2_WIDTH = 280;

export const DETAIL_PAGE_THUMBNAIL_V2_HEIGHT = 582;
export const DETAIL_PAGE_THUMBNAIL_V2_WIDTH = 824;

export const CONTINUE_READING_THUMBNAIL_V2_HEIGHT = 200;
export const CONTINUE_READING_THUMBNAIL_V2_WIDTH = 200;

export const CONTINUE_READING_THUMBNAIL_V2_ASPECT_RATIO = 1
export const BANNER_THUMBNAIL_V2_ASPECT_RATIO = 4/3
export const WIDGET_THUMBNAIL_V2_ASPECT_RATIO = 10/16
